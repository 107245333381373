import React, { useState, useRef, useEffect } from 'react';
import { Typography } from '@mui/material';
import y42 from './y42.js';
import i18n from './i18n.js';

export default function Countdown(props) {
	const [timer, setTimer] = useState(null);
    const [preAlerted, setPreAlerted] = useState(false);
    const [alerted, setAlerted] = useState(false);

    const cdRef = useRef(null);

    useEffect(() => {
        //console.log("Subscribed to timer");
        const client = y42.subscribeWS("/topic/timer?gameId=" + props.gameId, (message) => {
                    setTimer(message.body);
                    //console.log("Received timer: ", message.body);
                });
        client.activate();
        return () => client.deactivate();
    }, [props.gameId]);


    const isHidden = () => {
        if (cdRef.current === null) return null;
        var style = window.getComputedStyle(cdRef.current);
        return (style.display === 'none')
    }

    if (props.time === null)
        return <Typography variant="body1">{props.nullText}</Typography>;

    if (timer >= -1) {
        if (timer !== null && timer <= 0.5 && props.callback && !alerted && !isHidden()) {
            setAlerted(true);
            props.callback();                
        }
        if (timer !== null && !isNaN(props.preAlert) && !preAlerted && !isHidden()
            && timer <= props.preAlert + 0.5 && timer >= props.preAlert - 0.5 && props.preAlertCallBack) {
            setPreAlerted(true);
            props.preAlertCallBack();
        }
    }

    const formatTime = (time) => {
        let st = "";
        if (time === null)
            return "—";

        if (time < 0.5) {
            st = "—";
        }
        else
        {
            let d = Math.floor(time / (60 * 60 * 24));
            let h = Math.floor((time - (d * 60 * 60 * 24)) / (60 * 60));
            let m = Math.floor((time - (d * 60 * 60 * 24) - (h * 60 * 60)) / 60);
            let s = Math.floor((time - (d * 60 * 60 * 24) - (h * 60 * 60) - (m * 60)));

            st += d > 0 ? d + " " + i18n.t("lDays") + " " : "";
            st += h > 9 ? h + ":" : (h > 0 ? "0" + h  + ":" : "");
            st += (m > 9 ? m : "0" + m ) + ":";
            st += s > 9 ? s : "0" + s;
        }

    	return st;
    }

    return (<Typography ref={cdRef} sx={props.sx}>
    			{formatTime(timer)}
    		</Typography>);
}